<template>
	<div class="Home" style="min-width: 1900px;">
		<el-container>
			<el-header>
				<div class="header">
					<img src="../assets/home/homeLogo.png" />

					<div style="display: flex; width: 400px;justify-content: space-between;">
						<el-popover placement="bottom" width="400" trigger="click">
							<div>
								<div class="popover_flex">
									<img style="width: 100px;" src="../assets/home/gongzhonghao.jpg" />
									<div style="margin-left: 10px;">
										<span style="font-weight: bold; font-size: 20px;">应急安全智库</span>
										<div>最新政策/事故分析/海量干货/培训资料</div>
									</div>
								</div>
								<div class="popover_flex">
									<img style="width: 100px;" src="../assets/home/kefu.png" />
									<div style="margin-left: 10px;">
										<span style="font-weight: bold; font-size: 20px;">微信联系安小智</span>
										<div>anquan0311</div>
									</div>
								</div>
							</div>
							<div style="width: 100px;cursor: pointer;" slot="reference">联系我们</div>
						</el-popover>
						<!-- <div v-if="$store.state.is_test != 1" class="header_top"  @click="editPass">修改密码</div> -->

						<div class="header_top" @click="fillinfo">完善信息</div>
						<div class="header_top" @click="pay">会员资质</div>
						<div class="header_top" @click="outLogin">退出登录</div>
					</div>
				</div>
			</el-header>
			<el-main>
				<div style="cursor: pointer; width: 100%;" @click="show_ditail">
					<el-carousel height="385px" width="100%" @change="change_ditail_nb">
						<el-carousel-item v-for="(item, index) in bannerList" :key="index">
							<img :src="item" />
						</el-carousel-item>
					</el-carousel>
				</div>

				<div class="from_table">
					<div class="from_list">
						<div class="title">资料名称：</div>
						<el-input v-model="from_list.keywords" placeholder="请输入文件名称" style="width: 400px;"></el-input>
						<el-button class="eButton" style="margin-left: 20px;" type="primary" @click="searchData">搜索
						</el-button>
					</div>
					<div class="from_list">
						<div class="title">格式：</div>
						<el-radio-group v-model="from_list.format" @change="formatChange">
							<el-radio :label="0">全部</el-radio>
							<el-radio :label="1">文档</el-radio>
							<el-radio :label="2">视频</el-radio>
							<el-radio :label="3">音频</el-radio>
							<el-radio :label="4">课件</el-radio>
						</el-radio-group>
					</div>
					<div class="from_list" style="align-items: flex-start; justify-content: left;">
						<div class="title">分类：</div>
						<div class="listtitle">
							<el-radio-group v-model="from_list.category"
								style="display: flex; flex-wrap: wrap; width: 1000px;" @change="typeChange">
								<el-radio :label="0">全部</el-radio>
								<el-radio v-for="(item, index) in classifyList" :key='index' :label="item.id">
									{{item.name}}
								</el-radio>
							</el-radio-group>
						</div>
					</div>
				</div>
				<div style="padding:0 250px 100px; ">
					<el-table :stripe='true' ref="singleTable" :data="tableData"
						:header-cell-style="{ backgroundColor: '#EBEEF5' }" highlight-current-row style="width: 100%">
						<el-table-column type="index" align="center" width="100">
							<template scope="scope"><span>{{scope.$index+(current - 1) * 10 + 1}} </span></template>
						</el-table-column>
						<el-table-column property="name" label="资料名称" align="center">
						</el-table-column>
						<el-table-column property="category_name" label="分类" align="center" width="150">
						</el-table-column>
						<el-table-column property="format_name" align="center" width="150" label="格式">
						</el-table-column>
						<el-table-column property="address" align="center" width="150" label="价格">
							<template>
								<div>会员免费</div>
							</template>
						</el-table-column>
						<el-table-column v-if="vip==1" property="action" align="center" width="400" label="操作">
							<template slot-scope="scope">
								<div v-if="$store.state.is_test != 1">
									<el-button class="eButton" type="primary" @click="predivClick(scope.row)">查看详情
									</el-button>
									<!-- <el-button class="eButton" type="primary" @click='downLoadClick(scope.row)'>下载
									</el-button> -->
								</div>
							</template>
						</el-table-column>
						<el-table-column v-else property="action" align="center" width="400" label="操作">
							<template slot-scope="scope">
								<div v-if="$store.state.is_test != 1">
									<el-button class="eButton" type="primary" @click="predivClick_img(scope.row)">预览
									</el-button>
								</div>
							</template>
						</el-table-column>
					</el-table>

					<el-pagination :layout="show_page?'prev, pager, next':'prev, next'" :current-page="current"
						@current-change="currentChange" :total="total">
					</el-pagination>
				</div>

			</el-main>
			<el-footer>
				<div class=" footer">
					<div style="display: flex; flex-direction: column;">
						<img style="width: 150px;" src="../assets/home/gongzhonghao.jpg" />
						<span style="color: #FFFFFF;">加入智库</span>
					</div>
					<div
						style="display: flex;flex-direction: column; align-items: flex-start; margin-left: 100px; height: 80px;justify-content: space-between;">
						<div class="bottom_flex">
							<img style="width: 30px; margin-right: 20px;" src="../assets/home/danwei.png" />
							<div style="color: #FFFFFF;">主办单位：应急安全智库平台</div>
						</div>
						<div class="bottom_flex">
							<img style="width: 30px; margin-right: 20px;" src="../assets/home/kefu1.png" />
							<div style="color: #FFFFFF;">客服微信：anquan0311</div>
						</div>
					</div>
				</div>
			</el-footer>
		</el-container>
		<el-dialog title="温馨提示(下载超过次数)" :visible.sync="downExceed" width="30%">
			<span>您今天下载资料国语频繁,请明天重试或联系客服。</span>
			<div>
				<img src="../assets/home/kefu.png" />
				<div>
					<span>微信客服</span>
					<div>anquan0311</div>
				</div>
			</div>
		</el-dialog>
		<el-dialog title="修改密码" :visible.sync="editShow" width="20%" :close-on-click-modal="false">
			<el-form :model="editPassFrom" status-icon :rules="passRules" ref="editPassRuleForm" label-width="80px"
				class="demo-ruleForm">
				<el-form-item label="密码" prop="old_password">
					<el-input style="width: 100%;" v-model="editPassFrom.old_password"></el-input>
				</el-form-item>
				<el-form-item label="新密码" prop="password">
					<el-input style="width: 100%;" v-model="editPassFrom.password"></el-input>
				</el-form-item>
				<el-form-item label="确认密码" prop="password_confirmation">
					<el-input style="width: 100%;" v-model="editPassFrom.password_confirmation"></el-input>
				</el-form-item>

			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button class="eButton" @click="passCancel">取 消</el-button>
				<el-button class="eButton" type="primary" @click="passOk">确 定</el-button>
			</span>
		</el-dialog>
		<el-dialog title="完善会员信息" :visible.sync="fillinfoShow" width="20%" :close-on-click-modal="false">
			<el-form :model="fillinfoFrom" status-icon :rules="fillinfoRules" ref="fillinfoFrom" label-width="80px"
				class="demo-ruleForm">
				<el-form-item label="姓名" prop="name">
					<el-input style="width: 100%;" v-model="fillinfoFrom.name"></el-input>
				</el-form-item>
				<el-form-item label="手机号码" prop="mobile">
					<el-input style="width: 100%;" v-model="fillinfoFrom.mobile"></el-input>
				</el-form-item>
				<el-form-item label="单位名称" prop="unit_name">
					<el-input style="width: 100%;" v-model="fillinfoFrom.unit_name"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button class="eButton" @click="fileinfoCancel">取 消</el-button>
				<el-button class="eButton" type="primary" @click="fillinfoOk">确 定</el-button>
			</span>
		</el-dialog>
		<el-dialog title="分享得积分活动说明" :visible.sync="show_huodong" width="40%" :close-on-click-modal="false">

			<div style="display: flex;font-size: 20px;margin: 40px;line-height:100%;text-align: left;">
				<div style="min-width: 150px;font-weight: bold;">
					活动对象：
				</div>
				<div>
					所有正常登录“应急安全智库”微信小程序的用户。
				</div>
			</div>
			<div style="display: flex;font-size: 20px;margin: 40px;line-height:100%;text-align: left;">
				<div style="min-width: 150px;font-weight: bold;">
					活动期限：
				</div>
				<div>
					2022年6月1日 —— 2022年9月1日
				</div>
			</div>
			<div style="font-size: 20px;margin: 40px;line-height:150%;text-align: left;">
				<div style="min-width: 150px;font-weight: bold;">
					活动条件及内容：
				</div>
				<div>
					1、用户登录微信小程序“应急安全智库”首页，即“资料库”页面，进行分享操作，分享小程序至好友微信、微信群或朋友圈；<br>
					2、新用户通过分享链接首次成功打开“应急安全智库”小程序，分享人将获得一个积分，积分可在充值VIP会员时抵扣现金，规则见充值页面；<br>
					3、第2条中的“新用户”指从未打开过“应急安全智库”小程序的用户；<br>
					4、第2条中的“分享人”指从“应急安全智库”小程序首页创建分享的用户，在微信中转发他人分享信息，不会成为分享人；<br>
					5、分享人创建的分享链接被他人转发，分享人不变，即多次转发后，新用户点击此链接，分享人仍可获得积分；<br>
					6、新用户通过分享链接进入“应急安全智库”小程序、完善个人资料、购买VIP资格，分享人分别可获得1点、5点、20点积分。<br>
				</div>
			</div>
			<div style="font-size: 20px;margin: 40px;line-height:150%;text-align: left;">
				<div style="font-weight: bold;">
					本活动最终解释权归应急安全智库所有。
				</div>
				<div style="height: 50px;"></div>
			</div>
		</el-dialog>

	</div>
</template>

<script>
	import {
		mapState
	} from "vuex";
	import {
		request
	} from "../network/request.js";
	export default {
		name: 'Home',
		data() {
			var validatePass3 = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请输入密码'));
				} else {
					if (this.editPassFrom.password_confirmation !== '') {
						this.$refs.editPassRuleForm.validateField('password_confirmation');
					}
					callback();
				}
			};
			var validatePass4 = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请再次输入密码'));
				} else if (value !== this.editPassFrom.password) {
					callback(new Error('两次输入密码不一致!'));
				} else {
					callback();
				}
			};
			return {
				show_page: false,
				endtime: localStorage.getItem('user_stopat'),
				// user_id: this.$store.state.user_id,
				vip: localStorage.getItem('vip'),
				show_huodong: false, //活动详情展示页开关
				ditail_nb: 0, //跑马灯当前展示图片的序号
				info_bind: false, //提示老用户绑定微信
				info_fill: false, //提示完善信息
				editPassFrom: {
					id: '',
					old_password: '',
					password: '',
					password_confirmation: ''
				},
				fillinfoFrom: {
					id: '',
					name: '',
					mobile: '',
					unit_name: '',
					stop_at: ''
				},
				passRules: {
					old_password: [{
						required: true,
						message: '请输入原始密码',
						trigger: 'blur'
					}, ],
					password: [{
						validator: validatePass3,
						trigger: 'blur'
					}],
					password_confirmation: [{
						validator: validatePass4,
						trigger: 'blur'
					}],
				},
				fillinfoRules: {
					name: [{
						required: true,
						message: '请输入姓名',
						trigger: 'blur'
					}, ],
					mobile: [{
						required: true,
						message: '请输入手机号码',
						trigger: 'blur'
					}],
					unit_name: [{
						required: true,
						message: '请输入单位名称',
						trigger: 'blur'
					}],
				},
				fillinfoShow: false,
				payShow: false,
				editShow: false,
				current: 1,
				downExceed: false,
				total: 0,
				tableData: [],
				clientWidth: '',
				bannerList: [
					require('../assets/home/banner1.png'),
					require('../assets/home/jifenhuodong.png')
				],
				classifyList: [],
				from_list: {
					keywords: '',
					format: 0,
					category: 0,
					page: 1
				}
			}
		},
		created() {
			// console.log(this.user_id)
			this.clientWidth = document.documentElement.clientWidth
			if (this.$store.state.user_openid == null) {
				// console.log(this.$store.state.user_openid)
				this.$alert('本网站将从7月1日起全面启用扫码登录，请尽快在微信小程序“应急安全智库”,进入“个人中心”绑定付费账号。', '提示', {
					confirmButtonText: '确定',
				});
			} else if (this.$store.state.user_mobile == '') {
				this.$alert('请完善用户信息', '提示', {
					confirmButtonText: '确定',
					callback: action => {
						this.fillinfo();
					}
				});
			};
			// 获取分类列表
			request({
				url: 'datum/cultivate/material/category/set/list',
				method: 'post',
				data: {
					type: 1
				}
			}).then(res => {
				this.classifyList = res.data
			})
			// 获取资料列表
			this.getSet({
				page: 1,
				category: 0,
				format: 0,
				keywords: ''
			})

		},
		computed: {
			...mapState([
				'user_id'
			])
		},
		methods: {
			change_ditail_nb(e) {
				this.ditail_nb = e;
			},
			show_ditail() {
				if (this.ditail_nb == 1) {
					this.show_huodong = true;
				};
			},
			fileinfoCancel() {
				this.fillinfoShow = false
			},
			passCancel() { //取消修改密码
				this.editShow = false
			},
			passOk() { //确定修改密码
				this.$refs.editPassRuleForm.validate((valid) => {
					if (valid) {
						request({
							url: 'datum/user/change/password',
							method: 'post',
							data: this.editPassFrom
						}).then(res => {
							if (res.code == 1) {
								this.$message({
									type: 'success',
									message: '修改密码成功!',

								});
							}
						})
						this.editShow = false
					} else {
						return false;
					}
				});
			},

			fillinfoOk() {
				this.$refs.fillinfoFrom.validate((valid) => {
					if (valid) {

						request({
							url: 'datum/user/edit',
							method: 'post',
							data: this.fillinfoFrom,
						}).then(res => {
							if (res.code == 1) {
								this.$store.commit('userName', this.fillinfoFrom.name)
								this.$store.commit('userMobile', this.fillinfoFrom.mobile)
								this.$store.commit('userUnit_name', this.fillinfoFrom.unit_name)
								this.points_add(this.user_id, '', 2, 5) //给其他人增加积分
								this.$message({
									type: 'success',
									message: '信息上传成功!',
								});
								this.fillinfoShow = false
							}
						})
					} else {
						return false;
					}
				});
			},
			points_add(initiator_id, datum_user_id, type, integral) { //判断并添加分享积分
				//积分赠送发起人（送分的）id：initiator_id,当前登录用户（收分的）id：datum_user_id;type字段   1：点击赠送；2：注册赠送；3：购买赠送；4：消费抵扣
				request({
						url: 'datum/ntegral/award',
						method: 'post',
						data: {
							initiator_id: initiator_id,
							datum_user_id: datum_user_id,
							type: type,
							integral: integral
						}
					})
					.then((res) => {
						// console.log("增加分析分数一次");
						this.getponits(this.user_id)
					});
			},
			getponits(user_id) { //获取积分总数
				request({
						url: 'datum/integral/deduction',
						method: 'post',
						data: {
							datum_user_id: this.user_id
						}
					})
					.then((res) => {
						console.log("resssss", res)
						this.core = res.data.integral
						//初始化付款参数
						let price_temp = 99;
						let core_use_temp = 99;
						let price_core_temp = 0;
						// console.log("this.data.core", this.data.core);

						if (this.core <= core_use_temp) {
							core_use_temp = this.core;
						}
						price_core_temp = 99 - (core_use_temp / 2);
						console.log(111, price_temp, core_use_temp, price_core_temp);
						this.price = price_temp;
						this.core_use = core_use_temp;
						this.price_core = price_core_temp;
						console.log(222, this.price, this.core_use, this.price_core);
					});
			},
			editPass() { //修改密码
				this.editShow = true
				this.editPassFrom = {
					id: this.user_id,
					old_password: '',
					password: '',
					password_confirmation: ''
				}
			},
			fillinfo() { //完善会员信息
				this.fillinfoShow = true
				this.fillinfoFrom = {
					id: this.user_id,
					name: this.$store.state.user_name,
					mobile: this.$store.state.user_mobile,
					unit_name: this.$store.state.user_unit_name,
					stop_at: this.endtime,
				}
			},
			pay() { //会员资质
				const {
					href
				} = this.$router.resolve({
					path: "/pay",
				});
				window.open(href, '_blank');
				// this.$router.push({path:'/prediv', query:{list:e}})
			},
			getType(file) { //获取文件后缀
				var filename = file;
				var index1 = filename.lastIndexOf(".");
				var index2 = filename.length;
				var type = filename.substring(index1, index2);
				return type;
			},
			predivClick(e) { //预览
				const {
					href
				} = this.$router.resolve({
					path: "/preview",
				});
				window.open(href, '_blank');
				// this.$router.push({path:'/prediv', query:{list:e}})
				this.$store.commit('imgListTions', e)
				window.localStorage.setItem('imgList', JSON.stringify(e))
			},
			predivClick_img(e) { //预览
				const {
					href
				} = this.$router.resolve({
					path: "/preview_img",
				});
				window.open(href, '_blank');
				// this.$router.push({path:'/prediv', query:{list:e}})
				this.$store.commit('imgListTions', e)
				window.localStorage.setItem('imgList', JSON.stringify(e))
			},
			downLoadClick(e) {
				// console.log(e)
				request({
					url: 'datum/material/download',
					method: 'post',
					data: {
						warehouse_id: e.id
					}
				}).then(res => {

					if (res.code == 1) {
						if (res.data.file == null) {
							this.$message({
								type: 'error',
								message: '请进入预览界面操作!',

							});
						} else {
							let is_oss = res.data.file.is_oss == 1 ? 1 : 0;
							let suffix = this.getType(res.data.file.paths)
							window.open(
								`http://safety365.shenganyun.cn/v1/api/download?is_oss=${is_oss}&file_path=${res.data.file.paths}&file_name=${e.name + suffix}`
							)

						}
					} else if (res.code == -1) {
						this.downExceed = true
					}
				})
			},
			currentChange(e) {
				this.from_list.page = e
				this.current = e
				this.getSet(this.from_list)
			},
			formatChange(e) {
				this.from_list.format = e
			},
			typeChange(e) {
				this.from_list.category = e
			},
			searchData() {
				this.from_list.page = 1
				this.current = 1
				this.getSet(this.from_list)
			},
			getSet(data) {
				if (data.category == 0 && data.keywords == '') {
					this.show_page = false
				} else {
					this.show_page = true
				};
				request({
					url: 'datum/cultivate/material/set',
					method: 'post',
					data: data
				}).then(res => {
					this.tableData = res.data.data
					this.total = res.data.total
				})
			},
			outLogin() {
				this.$confirm('确认退出登录?', '退出登录', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					window.sessionStorage.removeItem('token')
					window.location.reload()
					this.$message({
						type: 'success',
						message: '退出登录成功!'
					});
				})

			}

		}

	}
</script>

<style lang="less" scoped>
	.el-radio {
		width: 150px;
		text-align: left;
	}

	.header {
		background-color: #FFFFFF;
		display: flex;
		align-items: center;
		justify-content: space-around;
	}

	* {
		padding: 0;
	}

	.from_list {
		display: flex;
		align-items: center;
		margin-bottom: 20px;

		.title {
			width: 100px;
			text-align: right;
			height: 30px;
			line-height: 30px;
			font-weight: bold;
		}

	}

	.eButton {
		width: 80px;
		height: 30px;
	}

	.from_table {
		padding: 50px 220px 0;
		margin-left: 20px;

		.el-radio {
			margin: 10px;
			margin-left: 0;
		}
	}

	.listtitle {
		.el-radio {
			margin: 10px;
			margin-left: 0;
		}
	}

	.footer {
		height: 250px;
		margin-left: -1000px;
		background-color: #033B6B;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.popover_flex {
		display: flex;
		align-items: center;

	}

	.bottom_flex {
		display: flex;
		align-items: center;
	}

	.header_top {
		cursor: pointer;
		margin-left: 20px;
		width: 200px;
	}

	.header_top1 {
		cursor: pointer
	}

	.header_top:hover {
		color: #409EFF;

	}
</style>
